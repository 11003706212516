'use client';

import { useEntrySearchStore, useSearchInputs } from '@liquorice/next-search';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useNavigationActions } from '../Navigation';
import Btn from '../ui/Btn';
import Grid from '../ui/Grid';

const SearchView = () => {
  const results = useEntrySearchStore((s) => s.results);
  const { inputProps, buttonProps } = useSearchInputs();
  const { setSearch } = useNavigationActions();
  const router = useRouter();
  const t = useTranslations('search');

  const { onClick } = buttonProps;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (buttonProps && onClick) {
      onClick(event);
      const inputValue = inputProps.value;
      if (!inputValue) return;

      event.preventDefault();
      setSearch(false);

      // console.log('search submitted');
      router.push(`/search/?q=${inputValue}`);
    }
  };

  if (results.length <= 0) return null;

  return (
    <Grid.Col offsetMd={2} md={8}>
      <Btn
        variant="outlined"
        endIcon="arrowRight"
        animateIcon
        size="large"
        uppercase
        animateDirection="right"
        onClick={handleClick}>
        {t('viewAll')}
      </Btn>
    </Grid.Col>
  );
};

export default SearchView;
