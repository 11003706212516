'use client';

import { NavMenuItem } from '@/lib/parse/nav';
import { parseUri } from '@liquorice/utils';
import { useNavigationActions } from '../Navigation';
import Btn, { BtnProps } from '../ui/Btn';
import Txt, { TxtProps } from '../ui/Txt';

type MenuItemProps = NavMenuItem & {
  BtnProps?: BtnProps<'a'>;
  TxtProps?: TxtProps<'span'>;
};

const MenuItem = ({
  id,
  href: rawHref,
  children,
  BtnProps,
  TxtProps,
  colorTheme,
}: MenuItemProps) => {
  const setMenu = useNavigationActions((s) => s.setMenu);
  const setMenuTheme = useNavigationActions((s) => s.setMenuTheme);
  const href = parseUri(rawHref);

  if (!href) return null;

  const handleClick = () => {
    setMenu(false);
    setMenuTheme('white');
  };

  return (
    <Btn as="a" key={id} variant="text" href={href} onClick={handleClick} {...BtnProps}>
      <Txt
        as="span"
        variant="navH1"
        onMouseEnter={() => setMenuTheme(colorTheme ?? 'white')}
        onMouseLeave={() => setMenuTheme('white')}
        {...TxtProps}>
        {children}
      </Txt>
    </Btn>
  );
};

export default MenuItem;
