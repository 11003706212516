'use client';

import { useCategoryChoice, useEntrySearchStore } from '@liquorice/next-search';
import Grid, { GridColProps } from '../ui/Grid';
import CaseStudyResult from './CaseStudyResult';

const customGridProps: GridColProps[] = [
  {
    md: 12,
  },
  {
    md: 7,
  },
  { md: 4, offsetMd: 1 },
  { md: 4 },
  {
    md: 7,
    offsetMd: 1,
  },
  {
    md: 12,
  },
];

export default function CaseStudyResults() {
  const results = useEntrySearchStore((s) => s.results);
  const { value } = useCategoryChoice({ name: 'category' });

  return (
    <Grid cx={{ rowGap: '5xl' }}>
      {results.map((result, i) => {
        return (
          <Grid.Col key={result.id} md={4} {...(value === '' && customGridProps[i])}>
            <CaseStudyResult
              id={result.id}
              CardVideoProps={{
                lockRatio: i >= 6 || value !== '',
                hover: i >= 6 ? true : false,
                disableHydration: true,
              }}
              CardImageProps={{ hover: i >= 6 ? true : false }}
              titleVariant={i >= 6 || value !== '' ? 'h4' : 'h3'}
            />
          </Grid.Col>
        );
      })}
    </Grid>
  );
}
