'use client';

import Grid from '@/components/ui/Grid';
// import { useBlockTheme } from '@/lib/utils/themeHelpers';
import Txt from '@/components/ui/Txt';
import { gql } from '@/graphql/__generated__';
import { parseBlockTheme } from '@/lib/parse/theme';
import { BlockContainer } from '../../BlockContainer';
import { createBlock } from '../../createBlock';

const TESTIMONIAL_BLOCK = gql(`
  fragment testimonialBlock on blocks_testimonial_BlockType {
    __typename
    id
    blockTheme {
        ...blockTheme
    }
    quote: htmlContentDefault
    author: genericText
  }
`);

const TestimonialBlock = createBlock(TESTIMONIAL_BLOCK, ({ data, meta }) => {
  const { quote, author } = data;

  const blockTheme = parseBlockTheme(data.blockTheme);

  return (
    <BlockContainer blockTheme={blockTheme} meta={meta} marginY>
      <Grid>
        {quote && (
          <Grid.Col xs={12} md={10} lg={9}>
            <Txt html>{quote}</Txt>
          </Grid.Col>
        )}
        {author && (
          <Grid.Col>
            <Txt as="p" variant="sm" uppercase headingFont letterSpacing="lede">
              {'— ' + author}
            </Txt>
          </Grid.Col>
        )}
      </Grid>
    </BlockContainer>
  );
});

export default TestimonialBlock;
